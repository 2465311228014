import React from 'react';
import Faq from './Faq/Faq';
import * as classes from './Faqs.module.css';

function Faqs(props) {
  const title = !props.forServiceDetail ? 'Frequently Asked Questions' : `Frequently Asked Questions for ${props.serviceTitle}`;
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <section className={`${classes.Wrapper} col-sm-10`}>
          <h1
            style={{
              borderBottom: '4px solid #CCC',
              display: 'inline-block',
            }}
            className="display-4"
          >
            {title}
          </h1>
          { !props.forServiceDetail
            ? props.data?.allSanityFaq?.edges.map((item, i) => <Faq key={i} question={item?.node?.title} answer={item?.node?._rawAnswer} />)
            : props.data?.sanityService?.connectedFAQ.map((item, i) => <Faq key={i} question={item?.title} answer={item?._rawAnswer} />)}
        </section>
      </div>
    </div>
  );
}

export default Faqs;
