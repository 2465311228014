// @ts-nocheck
import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import {
  imageSerializer,
  createImgBuilder,
  youtubeSerializer,
  blockSerializerWithAlignment,
  sanityClient,
} from "jrts-sanity"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import * as classes from "./ServiceDetail.module.css"
import FAQs from "../../FAQS/Faqs"
import { Box, Button, Flex, Heading } from "@chakra-ui/react"

const client = sanityClient("zbi98nq2", "production", "true")

const builder = createImgBuilder(client)

const serializers = {
  types: {
    imageBlockContent: props => imageSerializer(props, builder),
    youtube: ({ node }) => youtubeSerializer(node),
    block: blockSerializerWithAlignment,
  },
}

function Service(props) {
  return (
    <>
      <Box p={"24px"} pt={"48px"}>
        <div>
          <Flex
            flexDirection={{
              base: "column-reverse",
              sm: "row",
            }}
            justifyContent="flex-start"
            alignItems={{
              base: "flex-start",
              sm: "flex-end",
            }}
          >
            <Box
              pb={3}
              pr={"16px"}
              display="flex"
              flexDirection="column"
              flex={"0 1 50%"}
            >
              <Heading
                color="gray.800"
                pb={2}
                as="h1"
                size="2xl"
                borderBottom="2px solid #747073"
              >
                {props?.article?.title}
              </Heading>
              <Box pt={4}>
                <Button
                  variant="brand"
                  leftIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
                  onClick={() => props.showModal(true)}
                >
                  Request Consultation
                </Button>
              </Box>
            </Box>
            <Box pt={!props.figure ? '200px' : '0px'} flexBasis={"30%"} margin="auto">
              <Box
                  as="figure"
                  boxShadow={"2px 2px 9px -2px rgba(0,0,0, 0.25)"}
                  className="text-center"
                >
              {props.figure}
              </Box>
            </Box>
          </Flex>
          <hr />
          <Box className={classes.ServiceDescription}>
            <BlockContent blocks={props.blocks} serializers={serializers} />
          </Box>
        </div>
      </Box>
      {props?.article?.connectedFAQ &&
        props?.article?.connectedFAQ?.length > 1 && (
          <FAQs serviceTitle={props?.article?.title} forS {...props} />
        )}
    </>
  )
}

export default Service
