import React, { useEffect, useState } from "react"
import { sanityClient } from "jrts-sanity"
import { Link as GatsbyLink } from "gatsby"
import {
  Card,
  CardBody,
  Flex,
  Link,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
const client = sanityClient("zbi98nq2", "production", "true")

function ServicesListSidebar(props) {
  const [services, setServices] = useState(null)

  useEffect(() => {
    if (props?.categories?.length > 0) {
      const categories = props.categories.map(cat => {
        if (cat) {
          return cat.title
        }
        return ""
      })
      const category = categories[0]
      const query = `*[_type == 'service' && title != '${props.title}' && '${category}' in categories[]->title[]]{title,_id,'slug': slug.current, 'mainImageUrl': mainImage.asset->url}`

      client
        .fetch(query)
        .then(response => {
          setServices(response)
        })
        .catch(() => {})
    }
  }, [props.categories, props.title])

  return (
    <Card w="100%" m={4}>
      <CardBody>
        <Text fontSize="xl">Related Services</Text>
        {services ? (
          services.length > 0 &&
          services.map(service => (
            <Flex alignItems="flex-start" key={service.slug} className="my-2">
              <ArrowForwardIcon mt={1} mr={1} color={"laser.500"} />
              <Link
                color="gray.800"
                as={GatsbyLink}
                to={`/services/${service.slug}`}
              >
                {service.title}
              </Link>
            </Flex>
          ))
        ) : (
          <Stack>
            <Skeleton startColor="gray.50" endColor="gray.200" height="20px" />
            <Skeleton startColor="gray.50" endColor="gray.200" height="20px" />
            <Skeleton startColor="gray.50" endColor="gray.200" height="20px" />
          </Stack>
        )}
      </CardBody>
    </Card>
  )
}

export default ServicesListSidebar
