import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import BlockContent from '@sanity/block-content-to-react';
import {
  imageSerializer, createImgBuilder, youtubeSerializer, blockSerializerWithAlignment, sanityClient,
} from 'jrts-sanity';
import * as classes from './Faq.module.css';

const client = sanityClient('zbi98nq2', 'production', 'true');

const builder = createImgBuilder(client);

const serializers = {
  types: {
    imageBlockContent: (props) => imageSerializer(props, builder),
    youtube: ({ node }) => youtubeSerializer(node),
    block: blockSerializerWithAlignment,
  },
};

function Faq(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        tabIndex={props.i}
        onKeyDown={() => setOpen(!open)}
        role="button"
        className={classes.Button}
        onClick={() => setOpen(!open)}
        aria-controls="collapse-text"
        aria-expanded={open}
      >
        {props.question}
      </div>
      <Collapse in={open}>
        <div id="collapse-text">
          <BlockContent blocks={props.answer} serializers={serializers} />
        </div>
      </Collapse>
    </>
  );
}

export default Faq;
