import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../gatsby-starter/seo"
import Layout from "../../layout/layout"
import RequestConsultationForm from "../../Forms/RequestConsultation/RequestConsultation"
import Modal from "../../UI/Dropdown/Modal/Modal"
import Service from "./Service"
import ServicesListSidebar from "../ServicesListSidebar/ServicesListSidebar"
import { Box, Button } from "@chakra-ui/react"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ServiceDetail(props) {
  const [showModal, setShowModal] = useState(false)

  const article = props?.data?.sanityService



  return (
    <Layout logo={props?.data?.sanityHomePage?.logo.asset} tagline={props?.data?.sanityHomePage?.tagline}>
      <Box pb='15vh' className="container-fluid">
        <Seo title={article?.title} description={article?.descriptionShort} />
        <Modal show={showModal} modalClosed={() => setShowModal(false)}>
          <RequestConsultationForm
            startingServices={[
              { value: article?.title, label: article?.title },
            ]}
            modalClosed={setShowModal}
            styling={{ maxWidth: "100%" }}
          />
        </Modal>
        <div className="row">
          <div className="col-lg-9 px-0 position-relative overflow-hidden">
            <Service
              figure={
                  article.mainImage && article?.mainImage?.asset?.gatsbyImageData && (
                    <GatsbyImage
                      imgStyle={{ borderRadius: "4px" }}
                      image={article?.mainImage?.asset?.gatsbyImageData}
                      alt=""
                    />
                  )
              }
              article={article}
              blocks={article._rawServiceDescription}
              showModal={setShowModal}
            />
          </div>
          <div className="col-lg-3 d-flex justify-content-center align-items-center flex-column">
            {props?.data?.sanityService?.showRelated && (
              <ServicesListSidebar
                title={article.title}
                categories={article.categories}
              />
            )}
          </div>
        </div>
        <Button pl={2} variant='link' leftIcon={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate(-1)}>Back</Button>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    sanityHomePage {
      tagline
      logo {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: AUTO, width: 300)
        }
      }
    }
    sanityService(slug: { current: { eq: $slug } }) {
      title
      _rawServiceDescription(resolveReferences: { maxDepth: 10 })
      descriptionShort
      showRelated
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
          url
          id
        }
      }
      categories {
        title
      }
      connectedFAQ {
        title
        _rawAnswer
      }
    }
  }
`

export default ServiceDetail
